body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#qr-scanner
{
  width: 300px;
  height: 300px;
}


.enrollment {
  background: #fff;
  border: 3px solid black;
  box-shadow: 2px 3px 5px hsla(0,0%,77%,.25);
  /* min-width: 180px; */
  padding: 20px 50px;
  margin: 50px;

  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: bold; */
}